import React, { FC, useEffect } from 'react';
import { FormColumn, Row } from '../../../atoms/Layout';
import { CommunitySize, SpaceType, updateSpace, UpdateSpaceCommand } from '../../../api/spaces';
import { useAppContext } from '../../../context/AppContext';
import Loading from '../../../atoms/Loading';
import { Hint, Label } from '../../../atoms/Typography';
import { TextAreaInput, TextInput } from '../../../atoms/Input';
import { VerticalSpace2 } from '../../../atoms/Space';
import Button from '../../../atoms/Button';
import RadioGroup, { RadioOption } from '../../../molecules/RadioGroup';

export const COMMUNITY_SIZES: RadioOption[] = [
    {
        value: CommunitySize.RANGE_0_TO_1K,
        label: '0 - 1,000'
    },
    {
        value: CommunitySize.RANGE_1K_TO_10K,
        label: '1,000 - 10,000'
    },
    {
        value: CommunitySize.RANGE_10K_TO_100K,
        label: '10,000 - 100,000'
    },
    {
        value: CommunitySize.RANGE_100K_TO_500K,
        label: '100,000 - 500,000'
    },
    {
        value: CommunitySize.RANGE_500K_TO_1M,
        label: '500,000 - 1,000,000'
    },
    {
        value: CommunitySize.RANGE_1M_AND_UP,
        label: '1,000,000+'
    }
]

const Space: FC = () => {
    const space = useAppContext().space
    const [saving, setSaving] = React.useState<boolean>(false)
    const [saved, setSaved] = React.useState<boolean>(false)

    const [update, setUpdate] = React.useState<UpdateSpaceCommand>({
        name: '',
        spaceType: SpaceType.GAMING,
        welcomeEmailSent: false,
    })
    const setField = (field: keyof UpdateSpaceCommand) => (value: any) => setUpdate({ ...update, [field]: value })
    const setContextValue = (field: string) => (value: any) => setUpdate({ ...update, contextValues: { ...update.contextValues, [field]: value } })

    useEffect(() => {
        if (space.value) {
            setUpdate({
                name: space.value.name,
                spaceType: space.value.spaceType,
                companyName: space.value.companyName ?? undefined,
                gameTitle: space.value.gameTitle ?? undefined,
                welcomeEmailSent: space.value.welcomeEmailSent ?? undefined,
                companyMission: space.value.companyMission ?? undefined,
                goals: space.value.goals ?? undefined,
                goalsLongTerm: space.value.goalsLongTerm ?? undefined,
                companySummary: space.value.companySummary ?? undefined,
                gameDescription: space.value.gameDescription ?? undefined,
                productsAndServices: space.value.productsAndServices ?? undefined,
                targetAudience: space.value.targetAudience ?? undefined,
                communitySize: space.value.communitySize ?? undefined,
                contextValues: space.value.contextValues || {},
                communityDescription: space.value.communityDescription ?? undefined,
            })
        }
    }, [space])

    const invalidData = !update.name.trim() || !update.companyName?.trim()

    const onSubmit = async () => {
        if (!space.value || !update.name.trim() || !update.companyName?.trim()) {
            return
        }

        setSaving(true)

        try {
            const newSpace = await updateSpace(update)

            space.setValue(newSpace)
            setSaving(false)
            setSaved(true)
        } catch (e) {
            setSaving(false)
            console.error(e)
        }
    }

    if (!space) {
        return <Row size="full" center>
            <Loading size="large" />
        </Row>
    }

    return <FormColumn gap="small">
        <Label>{update.spaceType == SpaceType.GAMING ? 'Team/Game Name' : 'Team/Client Name' }</Label>
        <TextInput value={update.name} onChange={setField("name")} max={64} half />
        <VerticalSpace2 />
        <Label>Website URL</Label>
        <TextInput value={update.contextValues?.companyWebsite || ""} onChange={setContextValue("companyWebsite")} max={64} half />
        <VerticalSpace2 />
        <Label>Company Name</Label>
        <TextInput value={update.companyName || ""} onChange={setField("companyName")} max={64} half />
        <VerticalSpace2 />
        <Label>Provide a brief overview of your company's mission and vision</Label>
        <TextAreaInput max={4096} value={update.companyMission || ""} onChange={setField("companyMission")} placeholder="Our mission is to..." />
        <VerticalSpace2 />
        {
            space?.value?.spaceType === SpaceType.GAMING && <>
                <Label>Please describe your products / games to allow the AI to provide more targeted insights:</Label>
                <TextAreaInput max={4096} value={update.gameDescription || ""} onChange={setField("gameDescription")} placeholder="Fortnite is a multiplayer battle royale shooter.  Its primary gameplay features are…" />
                <VerticalSpace2 />
                <Label>Please describe your community to allow the AI to provide more targeted insights:</Label>
                <TextAreaInput max={4096} value={update.communityDescription || ""} onChange={setField("communityDescription")} />
                <VerticalSpace2 />
                <Label>Who is your primary target audience or customer base?</Label>
                <TextAreaInput max={4096} value={update.targetAudience || ""} onChange={setField("targetAudience")} placeholder="Primary, Secondary, Tertiary, Age, Gender & Demographics" />
                <VerticalSpace2 />
                <Label>How large is your community?</Label>
                <RadioGroup value={update.communitySize} options={COMMUNITY_SIZES} onChange={setField("communitySize")} />
                <VerticalSpace2 />
            </>
        }
        {
            space?.value?.spaceType === SpaceType.MARKETING && <>
                <Label>Please describe your value proposition / what makes you different to allow the AI to provide more targeted insights:</Label>
                <TextAreaInput max={4096} value={update.contextValues?.valueProposition || ""} onChange={setContextValue("valueProposition")}  />
                <VerticalSpace2 />
                <Label>What are the primary products or services you offer?</Label>
                <TextAreaInput max={4096} value={update.contextValues?.productsAndServices || ""} onChange={setContextValue("productsAndServices")} />
                <VerticalSpace2 />
                <Label>What product(s) will be the focus of your usage of the Voop platform?</Label>
                <TextAreaInput max={4096} value={update.contextValues?.primaryInterest || ""} onChange={setContextValue("primaryInterest")} />
                <VerticalSpace2 />
                <Label>Who is your primary target audience or customer base?</Label>
                <TextAreaInput max={4096} value={update.targetAudience || ""} onChange={setField("targetAudience")} />
                <VerticalSpace2 />
                <Label>How large is your customer base?</Label>
                <RadioGroup value={update.communitySize} options={COMMUNITY_SIZES} onChange={setField("communitySize")} />
                <VerticalSpace2 />
                <Label>What product metrics are most important to you?</Label>
                <TextAreaInput max={4096} value={update.contextValues?.productMetrics || ""} onChange={setContextValue("productMetrics")} />
                <VerticalSpace2 />
            </>
        }
        <Label>What are your short-term objectives for this feedback and research?</Label>
        <TextAreaInput max={4096} value={update.goals || ""} onChange={setField("goals")} />
        <VerticalSpace2 />
        <Label>What are your long-term objectives?</Label>
        <TextAreaInput max={4096} value={update.goalsLongTerm || ""} onChange={setField("goalsLongTerm")} />
        <VerticalSpace2 />
        { saved && <Hint>Space saved!</Hint> }
        <Row>
            <Button disabled={!space || invalidData} onClick={onSubmit} loading={saving}>Save</Button>
        </Row>
    </FormColumn>
}

export default Space;
