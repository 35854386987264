import React, { FC, useEffect } from 'react';
import { useAppContext } from '../context/AppContext';
import { Column, Row } from '../atoms/Layout';
import Loading from '../atoms/Loading';
import DateRangeInput from '../atoms/DateRangeInput';
import moment from 'moment';
import FeedbackFilters from '../organisms/FeedbackFilters';
import { FeedbackFilter, filterFeedback } from '../model/feedback';
import FeedbackItem from '../organisms/FeedbackItem';


const FeedbackViewer: FC = () => {
    const { feedback, channels } = useAppContext()
    const [filter, setFilter] = React.useState<FeedbackFilter>({
        startDate: moment('2020-01-01'),
        endDate: moment().endOf('day'),
    })
    const filteredFeedback = filterFeedback(feedback.value ?? [], filter)

    useEffect(() => {
        void feedback.load()
        void channels.load()
    }, [])

    if (feedback.loading || channels.loading) {
        return <Row center><Loading size="large" /> </Row>
    }

    return <Column size="full" gap="large">
        <Row>
            <DateRangeInput startDate={filter.startDate} endDate={filter.endDate} onChange={(startDate, endDate) => {
                setFilter({
                    ...filter,
                    startDate: startDate,
                    endDate: endDate,
                })
            }} />
        </Row>
        <Row gap="large">
            <FeedbackFilters filter={filter} setFilter={setFilter} feedback={feedback.value ?? []} channels={channels.value ?? []} />
            <Column size="full" gap="large">
                { filteredFeedback.slice(0, 300).map((feedback) => {
                    return <FeedbackItem key={feedback.id} feedback={feedback} channels={channels.value ?? []} />
                })}
            </Column>
        </Row>
    </Column>
}

export default FeedbackViewer;
